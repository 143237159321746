import type { OneLinkParams } from '@brand/config/app-banner'
import { appBannerData, RATING_COUNT } from '@brand/config/app-banner'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import Image from 'next/image'
import React from 'react'
import { Button } from '../../components/button/button'
import { IconButton } from '../../components/icon-button/icon-button'
import { Rating } from '../../components/rating/rating'
import styles from './app-banner.module.css'

export interface AppBannerProps {
  dataTagSection: string
  deepLinkParams?: OneLinkParams
  onClose?: () => void
}

export function AppBanner({
  dataTagSection,
  deepLinkParams,
  onClose,
}: AppBannerProps) {
  return (
    <div className={styles.appBanner} data-tag_section={dataTagSection}>
      <span className={styles.closeButton}>
        <IconButton
          icon={<CloseIcon aria-hidden="true" onClick={onClose} />}
          label={null}
          type="button"
          aria-label="Close"
          data-tid="app-banner-close"
          data-tag_action="ignore"
        />
      </span>

      <div className={styles.logo}>
        <Image
          src={appBannerData.logo}
          unoptimized
          width="55"
          height="55"
          alt={appBannerData.logoAltText}
        />
      </div>

      <div data-tag_item="open_area">
        <p className={styles.header}>Switch to the App</p>
        <p className={styles.body}>Find the perfect rental.</p>
        <div className={styles.rating}>
          <Rating percent={100} />
          <span className={styles.ratingCount}>{RATING_COUNT}</span>
        </div>
      </div>

      <div className={styles.ctaWrapper}>
        <Button
          variant="primary"
          rounded
          as="a"
          href={appBannerData.getSmartOneLink(deepLinkParams)}
          data-tag_item="install"
        >
          Download
        </Button>
      </div>
    </div>
  )
}
