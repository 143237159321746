'use client'

import { setIsomorphicCookie } from '@rentpath/cookies'
import { useCallback, useEffect } from 'react'
import type { AppBannerProps } from './app-banner'
import { AppBanner } from './app-banner'

import { APP_BANNER_COOKIE_KEY } from './app-banner.const'
import styles from './app-banner.module.css'
import clsx from 'clsx'
import { drawerOpen } from '../page-header/drawer-menu/drawer-menu.store'
import { useAtomValue } from 'jotai'
import { searchComboboxOpened } from '../search/search-combobox/search-combobox.store'
import { useAppBanner } from './app-banner.context'

const ONE_DAY_IN_SECONDS = 86_400

export type AppBannerSectionProps = AppBannerProps & {
  onClose?: () => void
}

export function AppBannerSection(props: AppBannerSectionProps) {
  const { isAppBannerShowing, setIsAppBannerShowing } = useAppBanner()

  const isMenuOpen = useAtomValue(drawerOpen)
  const isSearchComboboxOpened = useAtomValue(searchComboboxOpened)

  const onAppBannerLoad = useCallback(() => {
    window.eventTracker?.track('impression', {
      non_interaction: true,
      section: props.dataTagSection,
    })
  }, [props.dataTagSection])

  useEffect(() => {
    if (isAppBannerShowing) {
      onAppBannerLoad()
    }
  }, [onAppBannerLoad, isAppBannerShowing])

  function closeBanner() {
    setIsAppBannerShowing(false)
    props.onClose?.()
    setIsomorphicCookie(APP_BANNER_COOKIE_KEY, 'true', undefined, {
      maxAge: ONE_DAY_IN_SECONDS,
    })

    window.eventTracker?.track('click', {
      section: props.dataTagSection,
      non_interaction: false,
      item: 'x',
    })
  }

  if (!isAppBannerShowing) {
    return null
  }

  return (
    // don't remove this wrapper, it prevents CLS
    <div
      className={clsx(
        styles.appBannerWrapper,
        isMenuOpen || isSearchComboboxOpened
          ? styles.menuOpened
          : styles.menuClosed
      )}
      id="mobile-app-banner-wrapper"
    >
      <AppBanner
        onClose={closeBanner}
        dataTagSection={props.dataTagSection}
        deepLinkParams={props.deepLinkParams}
      />
    </div>
  )
}
